<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-overlay v-if="loading">
      <v-row
        class="mt-12"
      >
        <v-col class="d-flex justify-center">
          <ellipse-progress
            :loading="loading"
            :size="180"
            img-src="src/assets/images/termlife-icon.png"
            thickness="5"
            empty-thickness="3"
            line-mode="out 5"
            legend="false"
            animation="rs 700 1000"
            font-size="1.5rem"
            color="#00A1B7"
            empty-color-fill="transparent"
            filename="termlife-icon.png"
          >
          </ellipse-progress>
        </v-col>
      </v-row>
    </v-overlay>
    <!----Desktop-->
    <v-snackbar
      v-model="snackbar"
      data-testid="singlePartnerToast"
      xs12
      multi-line
      :timeout="timeout"
      position="fixed"
      top
      :color="snackbarColor"
    >
      {{ alertmessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <div class="hidden-md-and-down"> -->
    <div class="">
      <hero-component background-url="/img/Partnership-Background-Desktop.jpg">
        {{ $t("partnership.title") }}
      </hero-component>
      <div class="sec2-lg px-auto">
        <v-row class="justify-center">
          <v-col
            sm="10"
            class="sec2-title-desk"
          >
            {{ $t("partnership.section2.title") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col sm="10">
            <v-col
              cols="12"
              class="sec2-sub-desk"
            >
              {{ $t("partnership.section2.subheading") }}
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-start"
            >
              <v-icon
                left
                class="primary--text"
              >
                mdi-check-bold
              </v-icon>
              <span class="sec2-txt-desk">
                {{ $t("partnership.section2.list1") }}
              </span>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-start"
            >
              <v-icon
                left
                class="primary--text"
              >
                mdi-check-bold
              </v-icon>
              <span class="sec2-txt-desk">
                {{ $t("partnership.section2.list2") }}
              </span>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-start"
            >
              <v-icon
                left
                class="primary--text"
              >
                mdi-check-bold
              </v-icon>
              <span class="sec2-txt-desk">
                {{ $t("partnership.section2.list3") }}
              </span>
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div class="sec3-dsk">
        <v-row class="pt-16 justify-center">
          <v-col
            cols="11"
            sm="10"
            class="sec3-dsk-title"
          >
            {{ $t("partnership.section3.title") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            class="d-flex justify-center sec2-sub-desk-white text-center"
            cols="10"
          >
            {{ $t("partnership.section3.subheading1") }}
          </v-col>
        </v-row>
        <v-row class="justify-center mb-10">
          <v-col
            v-for="(product, i) in productCardsUSA"
            :key="product.id"
            :index="i"
            md="2"
            sm="4"
            cols="6"
            class=""
          >
            <v-card
              width="160px"
              height="200"
              class="product-card mx-auto mb-0"
              :class="product.status === 'active' ? 'zoom' : 'inactive'"
              elevation="0"
              color="transparent"
            >
              <v-row>
                <v-col class="pb-0">
                  <div
                    height="122"
                    width="112"
                  >
                    <v-img
                      :src="product.img"
                      class="mx-auto mt-2 prd-img"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="prd-card-title">
                  {{ $t(product.title) }}
                </v-col>
              </v-row>
              <!-- <v-row
                class="w-100"
              >
                <v-col class="px-0">
                  <div class="product-tag pt-2">
                    {{ product.tag }}
                  </div>
                </v-col>
              </v-row> -->
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            class="d-flex justify-center sec2-sub-desk-white text-center"
            cols="10"
          >
            {{ $t("partnership.section3.subheading2") }}
          </v-col>
        </v-row>
        <v-row class="mt-6 d-flex justify-center pb-16">
          <v-col class="text-center ml-6">
            <v-btn
              large
              class="mint apply-btn-txt"
              href="mailto:partnerships@blanket.com"
            >
              {{ $t("partnership.section3.contactusBtn") }}
            </v-btn>
            <!-- <v-btn
              v-if="currentCountryComputed !== 'CA'"
              large
              class="mint apply-btn-txt"
              text
              to="/contactus"
            >
              Contact Us
            </v-btn>
            <v-btn
              v-else
              large
              class="mint apply-btn-txt"
              text
              to="/contactus-ca"
            >
              Contact Us
            </v-btn> -->
          </v-col>
        </v-row>
      </div>
      <div class="sec2 px-auto py-16 bg-white">
        <v-row>
          <v-col class="sec2-title-desk">
            {{ $t("partnership.section4.title") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <v-row align="center">
              <v-col
                cols="12"
                md="6"
                order="2"
                order-md="1"
                class="sec2-sub-desk text-center text-md-left"
              >
                {{ $t("partnership.section4.subheading") }}
              </v-col>
              <v-col
                cols="12"
                md="6"
                order="1"
                order-md="2"
              >
                <v-img
                  src="/img/partnership/family.jpg"
                  class="w-100"
                ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec2-form-bg px-auto">
        <v-row class="justify-center">
          <v-col
            cols="11"
            sm="10"
            class="sec2-title-desk"
          >
            {{ $t("partnership.section5.title") }}
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="11"
            sm="10"
          >
            <v-col
              cols="12"
              class="sec2-sub-desk text-center"
            >
              {{ $t("partnership.section5.subheading") }}
            </v-col>
          </v-col>
        </v-row>
      </div>
      <div class="sec2-form px-auto">
        <v-row class="d-flex justify-center">
          <v-col
            cols="11"
            md="6"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="name"
                outlined
                data-testid="name"
                :rules="nameRules"
                :label="$t('partnership.form.name.label')"
                required
              ></v-text-field>
              <v-text-field
                v-model="company"
                data-testid="company"
                outlined
                :rules="[
                  (v) => !!v || $t('partnership.form.companyName.ruleMessage'),
                ]"
                :label="$t('partnership.form.companyName.label')"
                required
              ></v-text-field>
              <v-text-field
                v-model="companywebsite"
                data-testid="website"
                outlined
                :rules="[(v) => !!v || 'Website is required']"
                :label="$t('partnership.form.companySite.label')"
                required
              ></v-text-field>
              <v-text-field
                v-model="phone"
                v-mask="'###-###-####'"
                data-testid="phone"

                outlined
                :rules="phoneRules"
                :label="$t('partnership.form.phone.label')"
                required
              ></v-text-field>
              <v-text-field
                v-model="email"
                data-testid="email"
                :rules="emailRules"
                outlined
                :label="$t('partnership.form.email.label')"
                required
              ></v-text-field>

              <v-select
                v-model="industries"
                multiple
                outlined
                :items="products"
                :rules="[(v) => !!v || 'Industry is required']"
                :label="$t('partnership.form.industry.label')"
                required
              ></v-select>

              <v-textarea
                v-model="message"
                data-testid="message"
                outlined
                name="Your Message"
                :label="$t('partnership.form.message')"
                value="A message for Blanket"
              ></v-textarea>
              <div class="text-center">
                <v-btn
                  large
                  class="mint"
                  :disabled="!validForm"
                  data-testid="singlePartnerSaveButton"
                  @click="handleApplyPartner"
                >
                  <span class="apply-btn-txt">{{
                    $t("partnership.form.applyBtn")
                  }}</span>
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </div>

      <!-- <div class="sec7-dsk">
        <div class="about-frame">
          <v-row>
            <v-col class="sec5-title">
              About Blanket
            </v-col>
          </v-row>
          <v-row>
            <v-col class="about-txt">
              Blanket was founded by experienced insurance professionals who want to modernize the insurance experience by making it faster, easier, and digital. We’ve got YOU covered through a fresh, updated approach to insurance. <br> <br>

              Our platform makes buying insurance frictionless for customers. Our online experience allows consumers to secure insurance policies that meet their individual needs, wherever and whenever they need it - all supported by dedicated customer service agents...
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <v-btn
                large
                class="mint apply-btn-txt"
                to="/about/team"
              >
                learn more about us
              </v-btn>
            </v-col>
          </v-row>
          <v-img
            src="/img/Generali - logo.png"
            class="generali-logo"
          ></v-img>
          <v-img
            src="/img/stillwater - logo.png"
            class="stillwater-logo"
          ></v-img>
          <v-img
            :src="userCanadian ? '/img/Trupanion - logo.png' : '/img/trupanion_US_logo_white.png'"
            class="trupanion-logo"
          ></v-img>
          <v-img
            src="/img/afficiency - logo.png"
            class="afficiency-logo"
          ></v-img>
          <v-img
            src="/img/tugo - logo.png"
            class="tugo-logo"
            contain
          ></v-img>
        </div>
      </div> -->
    </div>
    <!----Tablet-->
    <!-- <div v-if="smOnly || mdOnly">
      <div class="sec1-tablet">
        <v-row>
          <v-col class="sec1-title-tablet">
            Blanket <br> Partnership
          </v-col>
        </v-row>
      </div>
      <div class="sec2-tablet">
        <v-row class="d-flex justify-center">
          <v-col cols="11">
            <v-col
              cols="12"
              class="sec2-title-tablet"
            >
              Become Part of the Blanket Family
            </v-col>
            <v-col
              cols="12"
              class="sec2-sub-tablet"
            >
              Let’s simplify, streamline, and digitize the insurance journey together.
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-start"
            >
              <v-icon
                class="primary--text"
                left
              >
                mdi-check-bold
              </v-icon>
              <span class="sec2-txt-tablet ">Joining the Blanket Family gives you the ability to enhance your relationships with your customers, providers, and community while we provide you access to technology, capital, and expertise for growth. We want to attract like-minded partners who also believe in delivering a best-in-class insurance experience not only for their clients but also their valued employees.</span>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-start"
            >
              <v-icon
                class="primary--text"
                left
              >
                mdi-check-bold
              </v-icon>
              <span class="sec2-txt-tablet ">Now is the time to join the digital-first Blanket family where you can leverage our technology platform to enhance your clients’ data and increase your revenue. Blanket’s simple, fast, and intuitive user experience combines with artificial intelligence for real time, data-driven decision making.</span>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-start"
            >
              <v-icon
                class="primary--text"
                left
              >
                mdi-check-bold
              </v-icon>
              <span class="sec2-txt-tablet ">Blanket’s investors and <router-link to="/about/team">leadership team</router-link> have vast experience building, operating and scaling global payment processing and fintech businesses.  The team has built start-ups into multi-billion dollar global companies that have traded on the TSX, NASDAQ, LSE, and NYSE and have completed over 40 successful M&A transactions. The team’s track record has proven an ability to drive shareholder value in private and public companies, unlock growth potential across an organization’s business, and orchestrate successful exits. Along the way, our executives have always emphasized a diverse and inclusive employee culture contributing to bottom-line success.</span>
            </v-col>
          </v-col>
        </v-row>
      </div>
    </div> -->
    <!----Mobile-->
    <!-- <div v-if="xsOnly">
      <div class="sec1-mobile"></div>
      <div class="sec2-title-mobile primary pa-10">
        Partner with Blanket
      </div>
      <div class="sec3-mobile px-3">
        <v-row>
          <v-col class="sec3-title-mobile">
            Become a Blanket Affiliate Partner today
          </v-col>
        </v-row>
        <v-row class="pt-10">
          <v-col class="sec3-sub-mobile">
            Getting insurance doesn’t have to be difficult. Bring your customers’ insurance journeys under one Blanket.
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              class="primary--text"
              left
            >
              mdi-check-bold
            </v-icon>
            <span class="sec2-txt-tablet ">Joining the Blanket Family gives you the ability to enhance your relationships with your customers, providers, and community while we provide you access to technology, capital, and expertise for growth. We want to attract like-minded partners who also believe in delivering a best-in-class insurance experience not only for their clients but also their valued employees.</span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              class="primary--text"
              left
            >
              mdi-check-bold
            </v-icon>
            <span class="sec2-txt-tablet ">Now is the time to join the digital-first Blanket family where you can leverage our technology platform to enhance your clients’ data and increase your revenue. Blanket’s simple, fast, and intuitive user experience combines with artificial intelligence for real time, data-driven decision making.</span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex align-start"
          >
            <v-icon
              class="primary--text"
              left
            >
              mdi-check-bold
            </v-icon>
            <span class="sec2-txt-tablet ">Blanket’s investors and <router-link to="/about/team">leadership team</router-link> have vast experience building, operating and scaling global payment processing and fintech businesses.  The team has built start-ups into multi-billion dollar global companies that have traded on the TSX, NASDAQ, LSE, and NYSE and have completed over 40 successful M&A transactions. The team’s track record has proven an ability to drive shareholder value in private and public companies, unlock growth potential across an organization’s business, and orchestrate successful exits. Along the way, our executives have always emphasized a diverse and inclusive employee culture contributing to bottom-line success.</span>
          </v-col>
        </v-row>
      </div>
    </div> -->
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="partnerErrorDialog"
          transition="dialog-bottom-transition"
          max-width="700"
          persistent
        >
          <v-card class="pa-4">
            <v-row>
              <v-col class="text-center text-h3 d-flex justify-center">
                {{ error }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-2 mt-4 font-weight-bold"
                    @click="
                      partnerErrorDialog = false;
                      clearError();
                    "
                  >
                    Close
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { emailRegex } from '@/consts/regex'
  import { normalizeToEnglish } from '@/utils/normalizeToEnglish'
  import HeroComponent from '@/components/base/Hero'
  import { partnerProducts } from '../../../utils/common'

  export default {
    components: {
      HeroComponent,
    },
    data () {
      return {
        loading: false,
        partnerErrorDialog: false,
        productCardsUSA: [
          {
            title: 'partnership.section3.travel',
            id: 1,
            route: '/travel/usa',
            learnRoute: '/travel/usa',
            img: '/img/partnership/travel.png',
            tag: 'Your adventures, covered.',
            txt: 'Learn More',
            status: 'active',
          },
          // {
          //   title: 'partnership.section3.pet',
          //   id: 6,
          //   route: '/pet',
          //   learnRoute: '/pet',
          //   img: '/img/partnership/pet.png',
          //   tag: 'Your best friend, covered.',
          //   txt: 'Learn More',
          //   status: 'active',
          // },
          {
            title: 'partnership.section3.condo',
            id: 2,
            route: '#',
            learnRoute: '#',
            img: '/img/partnership/condo.png',
            height: 122,
            width: 112,
            tag: 'Where your heart is, covered.',
            txt: 'Coming Soon',
            status: 'cs',
          },
          {
            title: 'partnership.section3.renters',
            id: 3,
            route: '/renters',
            learnRoute: '/renters',
            img: '/img/partnership/renters.png',
            tag: 'Your contents, covered.',
            txt: 'Learn More',
            status: 'active',
          },
          {
            title: 'partnership.section3.life',
            id: 4,
            route: '#',
            learnRoute: '#',
            img: '/img/partnership/life.png',
            tag: 'Your peace of mind, covered.',
            txt: 'Coming Soon',
            status: 'cs',
          },
        ],
        alertmessage: '',
        name: '',
        nameRules: [(v) => !!v || 'Name is required'],
        company: '',
        companywebsite: '',
        email: '',
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => emailRegex.test(v) || 'E-mail must be valid',
        ],
        industries: '',
        message: '',
        phone: '',
        phoneRules: [(v) => !!v || 'Phone Number is required'],
        products: partnerProducts,
        snackbar: false,
        snackbarColor: 'primary',
        timeout: 5000,
        valid: false,
      }
    },

    computed: {
      validForm () {
        let validform = false
        if (
          this.email &&
          this.phone &&
          this.company &&
          this.companywebsite &&
          this.industries
        ) {
          validform = true
        }
        return validform
      },
      xlOnly () {
        return this.$vuetify.breakpoint.xlOnly
      },
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smOnly () {
        return this.$vuetify.breakpoint.smOnly
      },
      mdOnly () {
        return this.$vuetify.breakpoint.mdOnly
      },
      lgOnly () {
        return this.$vuetify.breakpoint.lgOnly
      },
      currentCountryComputed () {
        let currentCountry = ''
        currentCountry = this.$store.getters.getWebsiteLocation
        return currentCountry
      },
      error () {
        const error = this.$store.getters.error || ''
        return error
      }
    },
    methods: {
      async handleApplyPartner () {
        const validForm = this.$refs.form.validate()
        const {
          name: partnerName,
          email: partnerEmail,
          company: companyName,
          message,
          phone,
          companywebsite: website,
          industries,
          partnerurl: partnerUrl,
        } = this

        if (validForm) {
          // Create partner in db
          const partnerPayload = {
            type: '',
            name: this.name,
            email: partnerEmail,
            message: this.message,
            phone: this.phone,
            company: this.company,
            companywebsite: this.companywebsite,
            industries: this.industries,
            partnerurl: new Date().getTime(),
            notes: '',
            active: 'NEW',
            timestamp: new Date().getTime()
          }
          if (!partnerEmail) { return }
          this.loading = true
          await this.$store.dispatch('createBlanketPartner', partnerPayload)
          if (this.error) {
            this.loading = false
            this.partnerErrorDialog = true
            return
          }
          await this.$store.dispatch('createGenericBlanketPartner', partnerPayload).then(() => {
            // Send email
            const emailPayload = {
              emails: [partnerEmail],
              subject: 'Your partnership with Blanket',
              body: 'Body',
              templateId: 'd-0c2ffbfd5cc14801acad561dec729c63',
              partnerUrl:
                `${window.location.origin}/partner/${normalizeToEnglish(partnerName)
                  .split(' ')
                  .join('_')
                  .trim()
                  .toLowerCase()}`,
            }

            // Send copy of form data to partnerships@blanket.com
            const copyEmailPayload = {
              emails: ['partnerships@blanket.com'],
              subject: `New Blanket partnership inquiry from: ${partnerName}@${companyName}`,
              body: `
                <h2>New Blanket partnership inquiry:</h2><br>
                <strong>Name:</strong> ${partnerName}<br>
                <strong>Email:</strong> ${partnerEmail}<br>
                <strong>Company:</strong> ${companyName}<br>
                <strong>Company Website:</strong> ${website}<br>
                <strong>Industries:</strong> ${industries.join(', ')}<br>
                <strong>Phone:</strong> ${phone}<br>
                <strong>Message:</strong> <br>${message}<br>
                `,
            }

            this.snackbarColor = 'primary'
            this.snackbar = true
            this.alertmessage =
              'Thank you for inquiring about a partnership with Blanket! A Blanket representative will reach out to you shortly.'
            this.valid = false
            this.$store.dispatch('createGenericBlanketPartner', emailPayload)
            // this.$store.dispatch('commsEmail', copyEmailPayload)
          }).catch(() => {
            this.snackbarColor = 'red'
            this.snackbar = true
            this.alertmessage =
              'There was an error submitting your form. Please try again.'
            this.valid = false
          }).finally(_ => {
            this.loading = false
          })

          // Reset
          this.$refs.form.reset()
          this.$refs.form.resetValidation()
        }
      },
      clearError () {
        this.$store.dispatch('clearError')
      },
    },
  }
</script>

<style lang="scss" scoped>
.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px 24px;
  gap: 10px;
  // background: #FFFFFF;
  height: 242px;
  // border: 1px solid #AAF0C1;
  // box-shadow: 0px 12px 32px rgba(50, 0, 79, 0.1);
  // border-radius: 8px;
}
// .prd-card-title {
// position: absolute;
// top: 137px;
// left: 0;
// font-family: 'Sora';
// font-style: normal;
// font-weight: 800;
// font-size: 30px;
// line-height: 110%;
// text-align: center;
// letter-spacing: -0.424019px;
// color: #00A1B7;
// }
.sec2-title-desk-teal {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  /* or 42px */

  text-align: center;
  letter-spacing: -0.424019px;

  /* Color Palette/Dark Grey */

  color: #005f6c;
}
.prd-card-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.sec5-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #ffffff;
}
.product-tag {
  position: absolute;
  /* top: 175px; */
  bottom: -5px;
  left: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  /* font-size: 16px; */
  font-size: 14.5px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* Color Palette/Dark Grey */
  color: #292f36;
}

.sec1-title-desk {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  /* or 57px */

  letter-spacing: -0.511589px;

  /* Color Palette/White (100%) */

  color: #ffffff;
}

.sec2-desk {
  background-image: url ();
}

.sec2-xl {
  position: relative;
  height: 850px;
  padding-top: 93px;
  background-image: url("/img/white-pattern-pet.jpg");
  background-position: center;
  background-size: cover;
}

.sec2-lg {
  position: relative;
  // height: 600px;
  padding-top: 93px;
  padding-bottom: 93px;
  background-image: url("/img/white-pattern-pet.jpg");
  background-position: center;
  background-size: cover;
}

.sec2-lg.dark-bg {
  background-image: url("/img/secondary-knit.jpg");
  background-size: 80%;
  background-repeat: repeat;
}
.sec2-lg.dark-bg .sec2-title-desk,
.sec2-lg.dark-bg .sec2-sub-desk,
.sec2-lg.dark-bg .sec2-txt-desk {
  color: #ffffff;
  text-align: left;
}
.sec5-dsk {
  position: relative;
  height: 356px;
  background: url("/img/dash-pattern.jpg");
}
.sec2-whitepanel {
  position: relative;
  height: 350px;
  padding-top: 93px;
  background: url("/img/dash-pattern.jpg");
  background-position: center;
  background-size: cover;
}
.sec2-form {
  position: relative;
  // height: 1150px;
  padding-top: 93px;
  padding-bottom: 93px;
  background-color: #ffffff;
}
.sec2-form-bg {
  padding-top: 93px;
  padding-bottom: 93px;
  position: relative;
  background: url("/img/dash-pattern.jpg");
  background-image: url("/img/dash-pattern.jpg");
  background-position: center;
  background-size: 70%;
}
.sec2-form-bg .sec2-title-desk,
.sec2-form-bg .sec2-sub-desk {
  color: #ffffff;
}

.secPartner1-title-desk {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  /* or 42px */

  text-align: center;
  letter-spacing: -0.424019px;

  /* Color Palette/Dark Grey */

  color: #292f36;
}
.sec2-title-desk-white {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  /* or 42px */

  text-align: center;
  letter-spacing: -0.424019px;

  /* Color Palette/Dark Grey */

  color: #ffffff;
}
.sec2-title-desk {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  /* or 42px */

  text-align: center;
  letter-spacing: -0.424019px;

  /* Color Palette/Dark Grey */

  color: #292f36;
}

.sec2-sub-desk-white {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 120%;
  /* or 34px */

  letter-spacing: -0.424019px;

  /* Color Palette/Dark Grey */

  color: #ffffff;
}
.sec2-sub-desk {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 120%;
  /* or 34px */

  letter-spacing: -0.424019px;

  /* Color Palette/Dark Grey */

  color: #292f36;
}

.sec2-txt-desk {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #292f36;
}
.s3-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  letter-spacing: -0.511589px;
  color: #ffffff;
}

.s3-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  /* identical to box height, or 114% */

  text-align: center;

  /* white */

  color: #ffffff;
}

.s3-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}
.sec7-dsk {
  position: relative;
  height: 570px;
  background: url("/img/partner-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.about-frame {
  position: absolute;
  width: 580px;
  height: 370px;
  left: 130px;
  top: 86px;
}
.section3-desk {
  height: 590px;
  background-image: url("/img/secondary-knit.jpg");
  background-position: center;
  background-size: cover;
  padding-top: 75px;
  padding-left: 165px;
  padding-right: 165px;
}

.sec3-desk {
  height: 240px;
  background-image: url("/img/partner-background.jpg");
  background-position: center;
  background-size: cover;
  padding-top: 80px;
  padding-left: 130px;
}

.sec3-title-desk {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #ffffff;
}
.apply-btn-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 125%;
  letter-spacing: 0.00892857em;
  color: #292f36 !important;
}
.apply-btn-txt:hover {
  text-decoration: none;
}

/*--------------TABLET-----------------*/

.sec1-tablet {
  position: relative;
  height: 600px;
  padding-top: 366px;
  padding-left: 70px;
  background-image: url("/img/Partnership-Background-Tablet.jpg");
  background-position: center;
  background-size: cover;
}
.sec3-dsk {
  // height: 720px;
  background: url("/img/secondary-knit.jpg");
}
.sec3-dsk-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #ffffff;
}
.dash-icons {
  font-size: 48px !important;
}
.sec3-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  /* or 114% */
  text-align: center;
  /* midnight eagle green */
  color: #ffffff;
}
.sec3-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;
  /* gunmetal */
  color: #ffffff;
}

.sec1-title-tablet {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  letter-spacing: -0.511589px;
  color: #ffffff;
}

.sec2-tablet {
  padding-top: 80px;
  background-image: url("/img/white-pattern-pet.jpg");
  background-position: center;
  background-size: cover;
  padding-bottom: 80px;
}

.sec2-title-tablet {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.sec2-sub-tablet {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.424019px;
}

.sec2-txt-tablet {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #292f36;
}

.sec3-tablet {
  height: 350px;
  background-image: url("/img/partner-background.jpg");
  background-position: center;
  background-size: cover;
  padding-top: 75px;
  padding-left: 65px;
}

.sec3-title-tablet {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 120%;
  /* or 50px */

  letter-spacing: -0.424019px;

  /* Color Palette/White (100%) */

  color: #ffffff;
}

.about-frame {
  position: absolute;
  width: 580px;
  height: 370px;
  left: 130px;
  top: 86px;
}
.about-txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
}
.generali-logo {
  position: absolute;
  width: 231.7px;
  height: 31.25px;
  left: 641px;
  top: 77px;
}
.stillwater-logo {
  position: absolute;
  width: 252.18px;
  height: 128.24px;
  left: 927.64px;
  top: 0;
}
.trupanion-logo {
  position: absolute;
  width: 232.05px;
  height: 69.27px;
  left: 641px;
  top: 170px;
}
.afficiency-logo {
  position: absolute;
  width: 139.36px;
  height: 52.76px;
  left: 927.64px;
  top: 170px;
}
.tugo-logo {
  position: absolute;
  width: 154px;
  height: 68.96px;
  left: 641px;
  top: 296px;
}

/*--------------MOBILE-----------------*/
.sec1-mobile {
  height: 385px;
  background-image: url("/img/Partnership-Background-Mobile.jpg");
  background-position: center;
  background-size: cover;
}

.sec2-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.511589px;
  color: #ffffff;
}

.sec3-mobile {
  background-image: url("/img/white-pattern-mobile-pet.jpg");
  background-position: center;
  background-size: cover;
  padding-top: 54px;
  padding-bottom: 70px;
}

.sec3-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.511589px;
  color: #292f36;
}

.sec3-sub-mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292f36;
}

.sec3-txt-mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #292f36;
}

.sec4-mobile {
  height: 450px;
  background-image: url("/img/partner-background.jpg");
  background-position: center;
  background-size: cover;
  padding-top: 65px;
}

.sec4-title-mobile {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  /* or 40px */

  text-align: center;
  letter-spacing: -0.511589px;

  /* white */

  color: #ffffff;
}

/* Small Screens */

@media screen and (max-width: 1110px) {
  .sec1-title-desk {
    width: 90%;
    text-align: center;
  }
}

@media screen and (max-width: 750px) {
  .sec1-title-desk {
    width: 80%;
    text-align: center;
    font-size: 43px;
  }
}

.s2-container {
  padding: 5rem 7rem 10rem 7rem;
}
.s2-icon {
  font-size: 50px !important;
}
.s2-subhead {
  font-family: "Sora";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #ffffff;
}
.s2-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}
@media screen and (max-width: 1110px) {
  .s2-container {
    padding: 5rem 1rem 10rem 1rem;
  }
}

@media screen and (max-width: 750px) {
  .sec2-title-desk,
  .sec2-title-desk-white,
  .sec3-dsk-title {
    font-size: 34px;
  }
  .sec2-sub-desk,
  .sec2-sub-desk-white {
    font-size: 24px;
  }
}
</style>
